import lkw from "../assets/autotypes/brian-stalter-arotxe540N4-unsplash.jpg";
import van from "../assets/autotypes/markus-winkler-3vlGNkDep4E-unsplash.jpg";
import wohnmobil from "../assets/autotypes/roadpass-XLW1d4eWVy0-unsplash.jpg";
import auto from "../assets/homepage/tyler-clemmensen-d1Jum1vVLew-unsplash.jpg";

const vehicles = [
  {
    type: "LKW",
    description:
      "Wir sind spezialisiert auf die Reinigung von großen Fahrzeugen wie LKWs. Wir sorgen dafür, dass jede Ecke sauber und glänzend ist.",
    image: lkw,
  },
  {
    type: "Vans",
    description:
      "Vans sammeln auf ihren Reisen allerlei Schmutz. Unsere Aufgabe ist es, sie tiefenrein zu säubern, sodass sie sich wieder wie neu anfühlen.",
    image: van,
  },
  {
    type: "Wohnmobile",
    description:
      "Wohnmobile sind Häuser auf Rädern. Wir reinigen sie mit Sorgfalt, um Ihnen eine komfortable Reise zu gewährleisten.",
    image: wohnmobil,
  },
  {
    type: "Autos",
    description:
      "Ob Limousine oder Kompaktwagen, wir kümmern uns um alle Arten von Autos. Unsere Reinigung lässt Ihr Auto wie neu aussehen.",
    image: auto,
  },
];

export default vehicles;
