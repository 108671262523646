import React from "react";
import { NavLink } from "react-router-dom";

import "./NavLinks.css";

const NavLinks = (props) => {
  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/" exact onClick={props.onClick}>
          STARTSEITE
        </NavLink>
      </li>
      <li>
        <NavLink to="/leistungen" onClick={props.onClick}>
          LEISTUNGEN
        </NavLink>
      </li>
      <li>
        <NavLink to="/pakete" onClick={props.onClick}>
          REINIGUNGSPAKETE
        </NavLink>
      </li>

      <li>
        <NavLink to="/kontakt" onClick={props.onClick}>
          KONTAKT
        </NavLink>
      </li>
      <li>
        <NavLink to="/about" onClick={props.onClick}>
          ÜBER UNS
        </NavLink>
      </li>
    </ul>
  );
};

export default NavLinks;
