import React from "react";
import "./WelcomeText.css";

const WelcomeText = () => {
  return (
    <div className="welcomeContainer">
      <h1 className="welcomeTitle">
        Herzlich willkommen bei unserer mobilen Autoaufbereitung!
      </h1>
      <p className="welcomeText">
        🚗 Wir erkennen die Wichtigkeit Ihrer Zeit und Ihres Komforts.{" "}
        <span className="highlightText">
          Deshalb bringen wir unseren Autoaufbereitungsservice direkt vor Ihre
          Haustür.
        </span>{" "}
        Sie müssen nicht zu uns kommen, wir kommen zu Ihnen und kümmern uns um
        Ihr Auto. Genießen Sie ein blitzsauberes Auto ohne Ihr Zuhause oder Ihr
        Büro verlassen zu müssen.
      </p>
    </div>
  );
};

export default WelcomeText;
