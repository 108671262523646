import React from "react";
import "./VehicleTypesSection.css";
import vehicles from "../models/vehicle-types";

const VehicleTypesSection = () => {
  return (
    <div className="vehicle-types-section">
      <h2 className="title">
        Wir pflegen jede Art von Fahrzeug - Finden Sie Ihres hier!
      </h2>
      {vehicles.map((vehicle, index) => (
        <div key={index} className="vehicle-item">
          <img src={vehicle.image} alt={vehicle.type} />
          <h3 className="vehicle-type">{vehicle.type}</h3>
          <p className="vehicle-description">{vehicle.description}</p>
        </div>
      ))}
    </div>
  );
};

export default VehicleTypesSection;
