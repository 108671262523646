const data = [
  {
    title: "Über Uns",
    content:
      "Jeder Weg beginnt mit einem ersten Schritt. Für uns begann dieser Weg mit einer tiefen Leidenschaft für makellose Fahrzeuge und einer Vision, die beste Autowaschanlage in der Stadt zu werden. Hier bei Top-Autoaufbereitung, wurde jeder Stein mit Hingabe und Begeisterung für das gelegt, was wir lieben - Autos in ihrem besten Licht erstrahlen zu lassen.",
  },
  {
    title: "Unsere Geschichte",
    content:
      "Top-Autoaufbereitung wurde geboren aus einem einfachen Traum, der sich in eine lebendige Realität verwandelte. Der Traum, Autobesitzern einen Ort zu bieten, wo sie sich darauf verlassen können, dass ihr Fahrzeug mit der größten Sorgfalt und Professionalität behandelt wird. Unsere Liebe zu Autos hat uns zusammengebracht und uns inspiriert, einen Unterschied in der Autowaschbranche zu machen.",
  },
  {
    title: "Unser Team",
    content:
      "Top-Autoaufbereitung wird von einem leidenschaftlichen und engagierten Team betrieben. Wir sind Autoliebhaber und Experten in unserem Fachgebiet, die den Traum teilen, jeden Kunden mit einem glänzenden, perfekt sauberen Auto glücklich zu machen. Jedes Mitglied unseres Teams trägt dazu bei, dass Ihr Besuch bei uns zu einem einzigartigen Erlebnis wird.",
  },
  {
    title: "Unsere Mission",
    content:
      "Unsere Mission bei Top-Autoaufbereitung ist einfach. Wir wollen jedem Kunden das höchste Maß an Service bieten und das bestmögliche Ergebnis erzielen. Kundenzufriedenheit steht bei uns an erster Stelle und wir sind bestrebt, bei jedem Besuch ein herausragendes Erlebnis zu bieten. Denn bei Top-Autoaufbereitung sind wir nicht nur leidenschaftlich daran interessiert, Autos zum Glänzen zu bringen, sondern auch daran, unseren Kunden ein Lächeln aufs Gesicht zu zaubern. Willkommen bei Top-Autoaufbereitung - Wo Leidenschaft auf Perfektion trifft.",
  },
];

export default data;
