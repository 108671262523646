import React from "react";
import ReactDOM from "react-dom";
import { AiOutlineClose } from "react-icons/ai";

import "./SideDrawer.css";

const SideDrawer = (props) => {
  const content = (
    <aside className="side-drawer" onClick={props.onClick}>
      <AiOutlineClose className="close-icon" onClick={props.onClose} />
      {props.children}
    </aside>
  );

  return ReactDOM.createPortal(
    content,
    document.getElementById("sidebar-hook")
  );
};

export default SideDrawer;
