import React from "react";

import data from "../models/about";
import "./AboutUsPage.css";

function AboutUsPage() {
  return (
    <div className="about-container">
      {data.map((item, index) => (
        <div key={index} className="about-section">
          <h2 className="section-title">{item.title}</h2>
          <p className="section-content">{item.content}</p>
        </div>
      ))}
    </div>
  );
}

export default AboutUsPage;
