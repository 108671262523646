import React, { useState } from "react";
import PackagesList from "../components/PackagesList";
import {
  overviewPackages,
  pkwVanSuvPackages,
  lkwPackages,
  wohnmobilPackages,
} from "../models/Packages";
import "./PackagesPage.css";

function PackagesPage() {
  const [selectedOption, setSelectedOption] = useState("PKWs/VAN/SUV");
  const [currentPackages, setCurrentPackages] = useState(pkwVanSuvPackages);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    switch (option) {
      case "PKWs/VAN/SUV":
        setCurrentPackages(pkwVanSuvPackages);
        break;
      case "LKWs":
        setCurrentPackages(lkwPackages);
        break;
      case "WOHNMOBIL":
        setCurrentPackages(wohnmobilPackages);
        break;
      default:
        setCurrentPackages(pkwVanSuvPackages);
    }
  };

  return (
    <>
      <h1 className="title-text">Unsere Pflegepakete für Ihr Fahrzeug</h1>
      <p className="intro-text">{overviewPackages}</p>
      <div className="button-container">
        <button
          className={`option-button ${
            selectedOption === "PKWs/VAN/SUV" ? "active" : ""
          }`}
          onClick={() => handleOptionChange("PKWs/VAN/SUV")}
        >
          PKWs/VAN/SUV
        </button>
        <button
          className={`option-button ${
            selectedOption === "LKWs" ? "active" : ""
          }`}
          onClick={() => handleOptionChange("LKWs")}
        >
          LKWs
        </button>
        <button
          className={`option-button ${
            selectedOption === "WOHNMOBIL" ? "active" : ""
          }`}
          onClick={() => handleOptionChange("WOHNMOBIL")}
        >
          WOHNMOBIL
        </button>
      </div>
      <PackagesList data={currentPackages} />;
    </>
  );
}

export default PackagesPage;
