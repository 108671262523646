import felgen from "../assets/leistungen/mason-jones-9e_ssIz4jB4-unsplash.jpg";
import motor from "../assets/leistungen/tim-mossholder-VurHDpO4VYI-unsplash.jpg";
import seat from "../assets/leistungen/ildar-garifullin-cEyfl-1YHRA-unsplash.jpg";
import polish from "../assets/leistungen/neelabh-raj-cw1914zDHUs-unsplash.jpg";
import leather from "../assets/leistungen/arteum-ro-YJQCsA0lXKQ-unsplash.jpg";
import aussen from "../assets/leistungen/clement-m-Ng3xrviPrhk-unsplash (1).jpg";

const leistungen = [
  {
    title: "Lackpflege",
    img: polish,
    description:
      "Der Autolack ist ständigen Witterungseinflüssen und mechanischen Belastungen ausgesetzt, die die Oberfläche verkratzen und den Lack stumpf und farblos wirken lassen können. Deshalb haben wir es uns zur Aufgabe gemacht, den Lack Ihres Fahrzeugs zu schützen und zu revitalisieren. Unsere professionelle und regelmäßige Lackpflege trägt entscheidend dazu bei, den Wert Ihres Fahrzeugs zu erhalten. Wir nutzen bewährte Techniken und hochwertige Produkte, um Ihren Lack zu reinigen, aufzubereiten und zu schützen, damit er trotz der Herausforderungen des Alltags glänzend und neu aussieht. Wir sind gerne bereit, Sie umfassend über die richtige Lackpflege und die optimalen Produkte zu beraten. Setzen Sie auf unsere Expertise, um Ihren Autolack in Bestzustand zu halten und das bestmögliche aus Ihrem Fahrzeug herauszuholen.",
  },
  {
    title: "Innenraumpflege",
    img: seat,
    description:
      "In unserem Service der Autoaufbereitung ist die Reinigung und Pflege des Innenraums von zentraler Bedeutung. Der Innenraum Ihres Fahrzeugs ist schließlich der Bereich, den Sie am häufigsten nutzen. Unser Expertenteam nutzt spezielle Produkte und individuelle Techniken für jedes Material, um den höchsten Komfort und das bestmögliche Erscheinungsbild zu gewährleisten. Von der Pflege feinster Leder- und Textiloberflächen bis hin zur Geruchsneutralisierung durch Ozonbehandlung - wir sorgen dafür, dass Ihr Auto immer wie neu aussieht und riecht. Auch bei der Entfernung von Tierhaaren gehen wir gewissenhaft vor, um stets ein sauberes und angenehmes Fahrerlebnis zu bieten. Vertrauen Sie auf unsere Innenraumreinigung, um die Schönheit und Langlebigkeit Ihres Autos zu erhalten.",
  },
  {
    title: "Motorwäsche",
    img: motor,
    description:
      "Die Motorwäsche ist ein wichtiger Bestandteil unserer Autoaufbereitungsdienstleistungen. Der Motor ist das Herz Ihres Fahrzeugs und verdient eine besondere Pflege, um seine Leistungsfähigkeit und Lebensdauer zu maximieren. Die Reinigung des Motors ist nicht nur eine Frage der Ästhetik. Es hat auch praktische Auswirkungen auf die Leistung und die Wartungsfähigkeit Ihres Fahrzeugs. Durch die Entfernung von Öl, Staub, Schmutz und anderen Ablagerungen, die sich im Laufe der Zeit angesammelt haben, kann der Motor effizienter laufen und besser abkühlen. Zudem können Probleme wie Öl- und Kühlmittel-Lecks leichter erkannt werden, wenn der Motor sauber ist. Mit unserer professionellen Motorwäsche sorgen wir dafür, dass Ihr Motor nicht nur sauber, sondern auch in bestmöglichem Zustand ist, um eine optimale Leistung und Langlebigkeit zu gewährleisten.",
  },
  {
    title: "Felgenpflege",
    img: felgen,
    description:
      "Die Felgen Ihres Fahrzeugs sind ständig den Elementen ausgesetzt und werden durch Bremsstaub, Straßenschmutz und andere Ablagerungen stark beansprucht. Deshalb ist es wichtig, sie regelmäßig zu reinigen und zu pflegen, um sie in bestmöglichem Zustand zu halten. Unsere Felgenpflege umfasst die Reinigung und Pflege von Stahl- und Leichtmetallfelgen, um sie vor Korrosion und anderen Schäden zu schützen. Vertrauen Sie auf unsere Expertise, um die Schönheit und Langlebigkeit Ihrer Felgen zu erhalten.",
  },
  {
    title: "Aussenwäsche",
    img: aussen,
    description:
      "Die äußere Schönheit Ihres Fahrzeugs ist das Erste, was ins Auge fällt. Mit unserer professionellen Außenreinigung sorgen wir dafür, dass Ihr Auto immer im besten Licht erscheint. Unsere Außenreinigung geht weit über eine einfache Autowäsche hinaus. Wir verwenden hochwertige Reinigungsmittel und innovative Techniken, um Schmutz, Staub und Ablagerungen zu entfernen, die sich auf der Oberfläche Ihres Fahrzeugs angesammelt haben. Gleichzeitig achten wir darauf, die Lackoberfläche und die sensiblen Teile wie Scheinwerfer und Fenster zu schützen. Wir reinigen nicht nur den Lack, sondern auch die Felgen, die Fenster und alle anderen äußeren Komponenten Ihres Autos. Unser Prozess zielt darauf ab, die ursprüngliche Brillanz und das neue Aussehen Ihres Fahrzeugs wiederherzustellen und gleichzeitig seine Langlebigkeit zu gewährleisten.",
  },
  {
    title: "Lederpflege",
    img: leather,
    description:
      "Leder ist ein hochwertiges und langlebiges Material, das bei richtiger Pflege über Jahre hinweg seine Schönheit und Geschmeidigkeit behält. Unsere professionelle Lederpflege bietet genau das - eine sorgfältige und sachkundige Behandlung, die Ihr Lederinterieur schützt und pflegt. Wir verwenden speziell formulierte Produkte, die das Leder reinigen, auffrischen und nähren, um es weich und geschmeidig zu halten. Unser Prozess hilft dabei, Risse, Austrocknen und Verblassen zu verhindern, und trägt dazu bei, dass das Leder seine Farbe und Textur behält. Aber unsere Lederpflege geht über die reine Konservierung hinaus. Wir bringen das Beste in Ihrem Lederinterieur zur Geltung, damit es immer so gut aussieht, wie es sich anfühlt. Mit unserer Lederpflege bleibt Ihr Fahrzeug nicht nur komfortabel und luxuriös, sondern es behält auch seinen Wert und sein Aussehen über die Zeit. Vertrauen Sie auf unsere Expertise in der Lederpflege, um die Langlebigkeit und Ästhetik Ihres Leders zu erhalten und zu fördern.",
  },
];

const overviewLeistungen =
  "Herzlich willkommen auf unserer Service-Seite! Bei uns finden Sie eine breite Palette an maßgeschneiderten Leistungen für die ganzheitliche Pflege Ihres Autos. Unser Ziel ist es, Ihren vier Rädern die bestmögliche Aufmerksamkeit und Sorgfalt zu widmen, damit sie stets in bestem Zustand bleiben. Unsere Services reichen von der sorgfältigen Lackpflege, um das Strahlen und die Langlebigkeit Ihrer Autolackierung sicherzustellen, über eine professionelle Motorwäsche, die dafür sorgt, dass Ihr Auto genauso gut läuft wie es aussieht, bis hin zur gründlichen Aussenreinigung, die Ihr Fahrzeug von oben bis unten sauber und glänzend hält. Aber unsere Arbeit endet nicht an der Autotür. Unser Team bietet auch eine sorgfältige Innenraumreinigung an, um sicherzustellen, dass Ihre Fahrt so angenehm ist wie am ersten Tag. Scrollen Sie weiter nach unten, um mehr über unsere einzelnen Services zu erfahren.";

export default leistungen;
export { overviewLeistungen };
