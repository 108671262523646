import React from "react";
import "./PackagesList.css";

const PackagesList = ({ data }) => (
  <div className="item-list">
    {data.map((item) => (
      <div key={item.title} className="item">
        <div className="item-image-container">
          <img className="item-image" src={item.img} alt={item.title} />
        </div>
        <div className="item-info">
          <h2 className="item-title">{item.title}</h2>
          <p className="item-description">{item.description}</p>
          <div className="item-prices">
            {Object.keys(item.price).map((priceKey) => (
              <p key={priceKey}>
                {priceKey}: ab {item.price[priceKey]}
              </p>
            ))}
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default PackagesList;
