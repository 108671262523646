import React from "react";
import { Link } from "react-router-dom";
import "./ContactUsSection.css";

const ContactUsSection = () => {
  return (
    <section className="contact-us-section">
      <div className="flip-card-container">
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <p>
                Bei weiteren Fragen, für detaillierte Informationen oder
                persönliche Beratungen, stehen wir Ihnen jederzeit zur
                Verfügung. Ihre Zufriedenheit ist unser oberstes Gebot. Zögern
                Sie nicht, uns zu kontaktieren.
              </p>
              <Link className="flip-card__link" to="/kontakt">
                Kontakt
              </Link>
            </div>
            <div className="flip-card-back">
              <h2 className="flip-card-back__title">
                Unsere Kontaktinformationen:
              </h2>
              <p>Telefon: +49 (163) 111-3232</p>
              <p>Email: info@top-autoaufbereitung.com</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
