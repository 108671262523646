import React from "react";
import Carousel from "../components/UIElements/Carousel";
import WelcomeText from "../components/WelcomeText";
import ServicesList from "../components/ServicesList";
import ContactUsSection from "../components/ContactUsSection";
import VehicleTypesSection from "../components/VehicleTypesSection";
import "./HomePage.css";

function HomePage() {
  return (
    <div>
      <Carousel />
      <WelcomeText />
      <hr className="hr-line" />
      <VehicleTypesSection />
      <hr className="hr-line" />
      <ServicesList />
      <hr className="hr-line" />
      <ContactUsSection />
    </div>
  );
}

export default HomePage;
