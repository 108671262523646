import React from "react";
import "./LeistungenList.css";

const LeistungenList = ({ data }) => {
  return (
    <div className="leistungen-container">
      {data.map((item, index) => (
        <div key={index} className="leistungen-card">
          <img src={item.img} alt={item.title} className="leistungen-image" />
          <div className="leistungen-text">
            <h2 className="leistungen-title">{item.title}</h2>
            <p className="leistungen-description">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LeistungenList;
