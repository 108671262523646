import React from "react";

import LeistungenList from "../components/LeistungenList";
import leistungen, { overviewLeistungen } from "../models/leistungen";
import "./LeistungenPage.css";

const LeistungenPage = () => {
  return (
    <>
      <h1 class="title-text">
        Unsere Auto-Pflegeleistungen: Exzellenter Service für Ihr Fahrzeug
      </h1>
      <p className="intro-text">{overviewLeistungen}</p>
      <LeistungenList data={leistungen} />;
    </>
  );
};

export default LeistungenPage;
