// Impressum.js

import React from "react";
import "./ImpressumPage.css";

function ImpressumPage() {
  return (
    <div className="impressum">
      <h2>Impressum</h2>
      <div className="info-section">
        <h3>Angaben gemäß § 5 TMG:</h3>
        <p>Top-Autoaufbereitung</p>
        <p>Alban-Stolz-Straße 1</p>
        <p>76571 Gaggenau</p>
      </div>
      <div className="info-section">
        <h3>Kontakt:</h3>
        <p>Telefon: +49 (163) 111-3232</p>
        <p>Email: info@top-autoaufbereitung.com</p>
      </div>
      <div className="info-section">
        <h3>Umsatzsteuer:</h3>
        <p>
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
        </p>
        <p>39106/32926</p>
      </div>
      <div className="info-section">
        <h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h3>
        <p>Abdul Saboor Ataei</p>
        <p>Alban-Stolz-Straße 1, 76571 Gaggenau</p>
      </div>
    </div>
  );
}

export default ImpressumPage;
