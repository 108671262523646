import React, { useState, useEffect } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import "./Carousel.css";

import img1 from "../../assets/homepage/guogete-bZ3UfXMD9HE-unsplash.jpg";
import img3 from "../../assets/homepage/jon-koop-khYVyHiNZo0-unsplash.jpg";
import img4 from "../../assets/homepage/campbell-3ZUsNJhi_Ik-unsplash.jpg";
import img5 from "../../assets/homepage/tyler-clemmensen-d1Jum1vVLew-unsplash.jpg";
import img6 from "../../assets/homepage/alan-king-1R63taCoSnM-unsplash.jpg";
import img7 from "../../assets/homepage/arteum-ro-TVFx7iFAAdQ-unsplash.jpg";

const Carousel = () => {
  const sourceImages = [img1, img3, img4, img5, img6, img7];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const autoplay = setInterval(() => {
      nextSlide(); // Will trigger the slide every 15 seconds
    }, 15000);

    return () => {
      clearInterval(autoplay); // This is important to clear the interval when the component is not unmounted
    };
  }, [currentIndex]); // The useEffect hook will run every time the currentIndex changes, and so the slideshow will be continuous

  const previousSlide = () => {
    const lastIndex = sourceImages.length - 1;
    const shouldResetIndex = currentIndex === 0;
    const index = shouldResetIndex ? lastIndex : currentIndex - 1;

    setCurrentIndex(index);
  };

  const nextSlide = () => {
    const lastIndex = sourceImages.length - 1;
    const shouldResetIndex = currentIndex === lastIndex;
    const index = shouldResetIndex ? 0 : currentIndex + 1;

    setCurrentIndex(index);
  };

  return (
    <div className="carousel">
      {sourceImages.map((source, index) => {
        let position = "nextSlide";
        if (index === currentIndex) position = "activeSlide";
        if (
          (currentIndex === 0 && index === sourceImages.length - 1) ||
          index === currentIndex - 1
        )
          position = "prevSlide";

        return (
          <img
            className={`carousel__image ${position}`}
            src={source}
            alt=""
            key={index}
          />
        );
      })}

      <button
        className="carousel__button carousel__button--left"
        onClick={previousSlide}
      >
        <FiChevronLeft size={30} />
      </button>
      <button
        className="carousel__button carousel__button--right"
        onClick={nextSlide}
      >
        <FiChevronRight size={30} />
      </button>
    </div>
  );
};

export default Carousel;
