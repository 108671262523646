import React from "react";
import "./DatenSchutzPage.css";

function DatenSchutzPage() {
  return (
    <div className="datenschutz">
      {" "}
      <h2>Datenschutzerklärung</h2>
      <div className="info-section">
        <p>
          Ihr Datenschutz ist uns sehr wichtig. Wir, Top-Autoaufbereitung,
          betreiben die Website www.top-autoaufbereitung.com und nehmen den
          Schutz Ihrer persönlichen Daten sehr ernst. Im Folgenden möchten wir
          Sie darüber informieren, dass auf unserer Website keine persönlichen
          Daten von Ihnen gesammelt oder gespeichert werden. Unsere Website
          dient ausschließlich dazu, Informationen über unser Unternehmen und
          unsere Dienstleistungen zu liefern. Wir verwenden keine Cookies,
          Tracking-Tools oder ähnliche Technologien, die dazu dienen,
          Informationen über das Verhalten der Besucher unserer Website zu
          sammeln. Falls wir in Zukunft entscheiden sollten, persönliche Daten
          zu sammeln, würden wir dies nur mit Ihrer ausdrücklichen Zustimmung
          tun und wir würden Sie informieren, welche Daten wir sammeln, wie wir
          sie verwenden und wie Sie Ihre Zustimmung widerrufen können. Sollten
          Sie Fragen zum Datenschutz haben, können Sie uns gerne unter
          info@top-autoaufbereitung.com kontaktieren.
        </p>
      </div>
      <h2>Hosting</h2>
      <div className="info-section">
        <p>
          Unsere Website wird auf den Servern von Hetzner Online GmbH,
          Industriestr. 25, 91710 Gunzenhausen, Deutschland, gehostet. Hetzner
          ist ein deutsches Unternehmen, das nach den Datenschutzgesetzen der
          Europäischen Union und Deutschlands reguliert wird. Hetzner
          verarbeitet Daten nur in dem Umfang, der erforderlich ist, um die
          Stabilität und Funktionalität der Website zu gewährleisten. Zu den
          verarbeiteten Daten gehören insbesondere IP-Adressen, Datum und
          Uhrzeit des Zugriffs, Browsertypen, Betriebssysteme und ähnliche
          technische Informationen, die für die Aufrechterhaltung des
          Serverbetriebs notwendig sind. Hetzner speichert diese Daten in
          sogenannten Server-Log-Dateien. Weitere Informationen zur
          Datenerhebung und -verarbeitung durch Hetzner finden Sie in der
          Datenschutzerklärung von Hetzner, die Sie unter dem folgenden Link
          abrufen können: https://www.hetzner.com/rechtliches/datenschutz/
        </p>
      </div>
    </div>
  );
}

export default DatenSchutzPage;
