import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import PackagesPage from "./pages/PackagesPage";
import LeistungenPage from "./pages/LeistungenPage";
import AboutUsPage from "./pages/AboutUsPage";
import ImpressumPage from "./pages/ImpressumPage";
import DatenSchutzPage from "./pages/DatenSchutzPage";
import MainNavigation from "./components/Navigation/MainNavigation";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <MainNavigation />
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/leistungen" element={<LeistungenPage />} />
        <Route path="/kontakt" element={<ContactPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/pakete" element={<PackagesPage />} />
        <Route path="/impressum" element={<ImpressumPage />} />
        <Route path="/datenschutzerklaerung" element={<DatenSchutzPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
